import React from 'react'

export default class Recipient extends React.Component {
  constructor(props) {
    super(props);
    this.state = { removed: false };
    this.handleRemove = this.handleRemove.bind(this);
    this.handleReadd = this.handleReadd.bind(this);
  }

  handleRemove(event) {
    event.preventDefault();
    this.setState({ removed: true });
    this.props.handleRemove(this.props.user.id);
  }

  handleReadd(event) {
    event.preventDefault();
    this.setState({ removed: false });
    this.props.handleReadd(this.props.user.id);
  }

  domId() {
    return this.props.user.type.toLowerCase() + '_' + this.props.user.id
  }

  render() {
    return (
      <div ref="slatRow" id={this.domId()} className={"slat row " + (this.props.user.newUser ? 'bg-light px-2' : '')} >
        <div className="col-12 col-sm">
          <h6 className="text-wordwrap">
            <a target="_blank" href={"/users/" + this.props.user.id}>{this.props.user.name}</a>
          </h6>
          <p className="font-size-sm text-wordwrap">{this.props.user.email}</p>
        </div>
        <div className="col">
          <h6 className="text-wordwrap">{this.props.user.ytd_donations_sum}</h6>
          <p className="font-size-sm mb-0">Year To Date</p>
        </div>
        <div className="col text-right">
          {this.state.removed ? (
            <a className="btn btn-secondary btn-sm" href="#" onClick={this.handleReadd}>
              <i className="fa fa-plus mr-1"></i>
              Re-Add
            </a>
          ) : (
            <span data-placement="top" data-toggle="tooltip" title="" data-original-title="Remove from recipients list.">
              <a className="btn btn-secondary btn-sm" href="#" onClick={this.handleRemove}>
                <i className="fa fa-trash mr-1"></i>
                Remove
              </a>
            </span>
          )}
        </div>
      </div>
    );
  }
}
