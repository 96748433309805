import React, {useEffect, useRef} from 'react';

export default function  Select2Wrapper(props) {
  const ele = useRef(null);
  useEffect(() => {
    let $el = ele.current;
    if($el !== null){
      let $els = $($el);
      $els.select2(props.data !== undefined ? props.data : {}).off('select2:select').off('select2:unselect').on("select2:select", (event) => {
        if(props.onChange !== undefined )
          props.onChange(event)
      }).on('select2:unselect', (event) => {
        if(props.onChange !== undefined ) {
          setTimeout(function() { 
            props.onChange(event);
         }, 0)
        }
          
      });
      if(props.value !== undefined)
        $els.val(props.value).trigger('change.select2');
    }
  });
  
  return(
    <div>
      <select {...props} ref={ele}>
        {props.children}
      </select>
    </div>
  )
}