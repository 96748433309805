import React, { useState, useMemo } from 'react'
import Select2Wrapper from 'components/Select2Wrapper'

export default function AddRecipient(props) {
  const [contact, setContact] = useState(undefined)
  const [disableAdd, setDisableAdd] = useState(true)
  const [contacts, setContacts] = useState([])

  const handleSelectChange = (e) => {
    if(e.type == 'select2:select') {
      setDisableAdd(false)
      setContact(e.target.value)
    } else {
      setDisableAdd(true)
      setContact(undefined)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const selectedContact = contacts.find((c) => { return c.id.toString() == contact })
    props.handleAdd(selectedContact)
    setDisableAdd(true)
    setContact('')
  }

  // memorize to avoid unnecessary Re-renders
  const selectField = useMemo(() => <Select2Wrapper value={ contact } onChange={ handleSelectChange }
     id={'contact_id'} name={'contact_id'}
    data={{
      allowClear: true,
      minimumInputLength: 1,
      placeholder: "Contact Search...",
      ajax: {
        delay: 250,
        url: `/individuals.json`,
        data(params) {
          return {
            'q[id_not_eq]': 'Exception',
            'q[first_last_name_cont]': params.term,
            page: params.page
          };
        },
        processResults(users) {
          const results = [];
          setContacts(users);
          $.each(users, (index, user) =>
            results.push({
              id: user.id,
              text: user.name,
              disabled: props.existingUserIds.indexOf(user.id) != -1
            })
          );
          return { results };
        }
      }
    }}/>, [contact, props.existingUserIds]);

  return (
    <div className="mt-4 pt-4 border-top">
      <div className="media align-items-center">
        <div className="pr-3">
          Add recipients:
        </div>
        <div className="media-body">
          { selectField }
        </div>
        <div className="pl-2">
          {disableAdd ? (
            <span title="" data-toggle="tooltip" data-placement="top" className="d-inline-block" data-original-title="Select a recipient to add.">
              <a className="btn btn-secondary submit disabled add">
                <i className="fa fa-plus-circlemr-1"></i>
                Add
              </a>
            </span>
          ) : (
            <a className="btn btn-secondary submit add" href="#" onClick={handleSubmit}>
              <i className="fa fa-plus-circle mr-1"></i>
              Add
            </a>
          )}
        </div>
      </div>
      <div className="form-text text-right text-muted">
        You have added {props.userIdsToAdd.length} new {props.userIdsToAdd.length == 1 ? 'recipient' : 'recipients'} to the list.
        { props.userIdsToAdd.length > 0 ? <strong> Click Update to save your changes.</strong> : ''}
      </div>
    </div>
  )
}
