import React from 'react'
import Recipient from 'components/Recipient'
import AddRecipient from 'components/AddRecipient'

export default class RecipientsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { users: [], totalCount: 0, page: 1, userIdsToRemove: [], userIdsToAdd: [] ,scrollIsLocked: false, disableApply: true };
    this.handleRemove = this.handleRemove.bind(this);
    this.handleReadd = this.handleReadd.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.baseState = this.state
  }

  componentDidMount() {
    this.loadRecipients(1);
    this.initInfiniteScroll();
    this.bindModalClose();
  }

  resetComponent = () => {
    if(this.state.userIdsToRemove.length || this.state.userIdsToAdd.length) {
      this.setState(this.baseState)
      this.loadRecipients(1);
      this.initInfiniteScroll();
    }
  }

  initTooltips() {
    var modal = this.refs.modal;
    $(modal).find('[data-toggle="tooltip"]').tooltip({ trigger: 'hover', container: '.modal' });
  }

  bindModalClose() {
    $(this.refs.modal).on('hidden.bs.modal', () => { this.resetComponent() } )
  }

  hideTooltips() {
    var modal = this.refs.modal;
    $(modal).find('[data-toggle="tooltip"]').tooltip('hide');
  }

  componentDidUpdate() {
    this.initTooltips();
  }

  componentWillUpdate() {
    this.hideTooltips();
  }

  initInfiniteScroll() {
    var _this = this;
    $(this.refs.scrollContainer).off('scroll').on('scroll', function() {
      if(!_this.state.scrollIsLocked && _this.scrollThresholdReached()) {
        _this.loadRecipients(_this.state.page + 1);
      }
    });
  }

  disableInfiniteScroll() {
    $(this.refs.scrollContainer).off('scroll');
  }

  scrollThresholdReached() {
    var scrollContainer = $(this.refs.scrollContainer);
    var totalHeight = scrollContainer.get(0).scrollHeight;
    return (scrollContainer.scrollTop() + scrollContainer.height()) >= (totalHeight - 100);
  }

  loadRecipients(page) {
    this.lockScroll();

    var _this = this;
    $.getJSON('/snapshot_groups/' + this.props.group.id + '/users?page=' + page, function(data) {
      if(data.users.length < 25)
        _this.disableInfiniteScroll();

      if(page == 0)
        var newUsers = data.users;
      else
        var newUsers = _this.state.users.concat(data.users);
      _this.setState({ users: newUsers, page: page, totalCount: data.total_count, scrollIsLocked: false });
    });
  }

  lockScroll() {
    this.setState({ scrollIsLocked: true });
  }

  disableSubmit() {
    return (this.state.userIdsToRemove.length < 1 && this.state.userIdsToAdd.length < 1);
  }

  handleRemove(userId) {
    let user = this.findUser(userId);
    if(user && user.newUser) {
      let users =  this.state.users.filter(u => u.id !== userId);
      let index = this.state.userIdsToAdd.indexOf(userId);
      this.state.userIdsToAdd.splice(index, 1);
      this.setState({ users,
        userIdsToAdd: this.state.userIdsToAdd,
        totalCount: this.state.totalCount - 1,
        disableApply: this.disableSubmit()
      });
    } else {
      let userIdsToRemove = this.state.userIdsToRemove.concat([userId]);
      this.setState({ userIdsToRemove: userIdsToRemove, disableApply: false});
    }
  }

  handleReadd(userId) {
    var index = this.state.userIdsToRemove.indexOf(userId);
    this.state.userIdsToRemove.splice(index, 1);
    this.setState({ userIdsToRemove: this.state.userIdsToRemove,  disableApply: this.disableSubmit() });
  }

  handleAdd(user) {
    if(this.findUser(user.id) !== undefined)
      return;

    user.newUser = true;
    // put user at top
    this.state.users.unshift(user);
    let userIdsToAdd = this.state.userIdsToAdd.concat([user.id]);
    this.setState({
      users: this.state.users,
      userIdsToAdd,
      totalCount: this.state.totalCount + 1,
      disableApply: false}, () => {
        let addElement = document.getElementById("individual_"+user.id)
        if(addElement)
          addElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
      });

  }

  findUser(id) {
    return this.state.users.find((u) => u.id === id);
  }

  handleSubmit() {
    var answer = confirm('Are you sure you want to apply changes?');
    if(!answer) return;

    $.ajax({
      url: '/snapshot_groups/' + this.props.group.id + '/users',
      data: { removed_ids: this.state.userIdsToRemove, added_ids: this.state.userIdsToAdd },
      type: 'PUT',
      success: function() {
        $(this.refs.modal).modal('hide');
        let users = this.state.users.filter(({id}) => (this.state.userIdsToRemove.indexOf(id) == -1));
        users = users.map(({newUser, ...keepAttrs}) => keepAttrs)
        let totalCount = this.state.totalCount - this.state.userIdsToRemove.length;
        this.setState({ users, totalCount, userIdsToRemove: [], userIdsToAdd: [] });
        $('.snapshot-group-user-count').text(totalCount);
      }.bind(this)
    });
  }

  render() {
    return (
      <div ref="modal" aria-hidden="true" aria-labelledby="myModalLabel" className="modal fade orange" id="snapshot_group_users_modal" role="dialog" >
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title pull-left">Review &amp; Edit Recipients</h5>
              <button type="button" aria-label="Close" className="close" data-dismiss="modal" href="javascript:;">&times;</button>
            </div>
            <div className="modal-body">
              <h4>
                <span className="snapshot-group-user-count">{this.state.totalCount}</span>
                &nbsp;
                <span className="display-4">contacts in this recipient list</span>
              </h4>
              <div>
                <div className="slat-list mt-3 pr-2" id="scroll_container"  ref="scrollContainer" style={{height: 315, overflowY: 'auto', overflowX: 'hidden'}}>
                  {
                    this.state.users.map(function(user) {
                      return <Recipient key={user.id} user={user} handleRemove={this.handleRemove} handleReadd={this.handleReadd}/>
                    }.bind(this))
                  }
                </div>
              </div>
              {this.state.scrollIsLocked ? (
                <p className="loading text-muted text-center pt-3 mb-0">
                  <i className="fa fa-spinner fa-pulse text-muted"></i>
                  &nbsp;
                  Loading
                </p>
              ) : null}
              <AddRecipient handleAdd= {this.handleAdd } userIdsToAdd= {this.state.userIdsToAdd} existingUserIds= {this.state.users.map(u => u.id)} />
            </div>
            <div className="modal-footer">
              {this.disableSubmit() ? (
                <span title="" data-toggle="tooltip" data-placement="top" className="d-inline-block" data-original-title="Add or remove recipients to update any changes.">
                  <a className="btn btn-primary submit disabled apply btn-lg">
                    Update
                  </a>
                </span>
              ) : (
                <a className="btn btn-primary btn-lg submit apply" href="#" onClick={this.handleSubmit}>
                  Update
                </a>
              )}
              <a className="btn btn-link" data-dismiss="modal" href="#">
                Cancel
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
