import React from 'react'

export default class GraphStatsSummaryLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = { expanded: props.expanded };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    this.setState({ expanded: !this.state.expanded });
    this.saveAdminPreference();
  }

  saveAdminPreference() {
    var key = 'collapse_graph_stats_summary';
    var value = this.state.expanded
    $.post('/admin_preferences.json', { _method: 'PUT', key: key, value: value });
  }

  permanentExpirationDate() {
    var d = new Date();
    d.setTime(d.getTime() + (20 * 365 * 24 * 60 * 60 * 1000)); // 20 years, same as Rails cookies.permanent
    return d.toUTCString();
  }

  render() {
    return (
      <a onClick={this.handleClick} id="toggle_graph_stats_summary" className="btn btn-secondary collapsed" data-toggle="collapse" aria-expanded="{expanded}" aria-controls="graph_stats_summary" href="#graph_stats_summary">
        <i className="fa fa-line-chart mr-1"></i> {this.state.expanded ? 'Hide' : 'Show'} Graph & Stats
      </a>
    );
  }
}
